import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO, { SeoFrontmatter } from 'components/SEO';
import Projects, { ProjectsFrontmatter } from 'components/Projects';
import locales from 'constants/locales';

interface Props {
    pageContext: { locale: keyof typeof locales };
    data: {
        projects: { frontmatter: ProjectsFrontmatter };
        seo: {
            frontmatter: SeoFrontmatter;
        };
    };
}

const ProjectsPage: React.FC<Props> = ({ pageContext: { locale }, data: { projects, seo } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                path="projects"
                title={seo.frontmatter.title}
                description={seo.frontmatter.description}
                locale={seo.frontmatter.locale}
            />
            <Projects data={projects} />
        </Layout>
    );
};

export const query = graphql`
    query ProjectsQuery($locale: String!) {
        seo: markdownRemark(
            frontmatter: { category: { eq: "seo-projects" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                description
                locale
            }
        }
        projects: markdownRemark(
            frontmatter: { category: { eq: "projects" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                projects {
                    title
                    technologies
                    description
                    links {
                        href
                        label
                    }
                }
            }
        }
    }
`;

export default ProjectsPage;
