import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Projects = styled.div`
    ${tw`w-full flex flex-wrap`};
`;

export const Project = styled.div`
    ${tw`w-full sm:w-1/2 p-3`};
`;

export const Card = styled.div`
    ${tw`p-3 w-full h-full rounded-lg flex flex-col overflow-hidden border border-secondary bg-secondary`};
`;

export const Content = styled.div`
    ${tw`p-4 text-indigo-900`};
`;

export const Title = styled.h3`
    ${tw`font-semibold mb-4 text-lg text-white`};
`;

export const Description = styled.p`
    ${tw`text-gray-500`}
`;

export const Date = styled.h3`
    ${tw`text-xs text-indigo-500`};
`;

export const Technologies = styled.div`
    ${tw`px-4 pt-4 mt-auto flex flex-wrap justify-center items-center`}
`;

export const Technology = styled.span`
    ${tw`p-1`}
    & img {
        width: 35px;
        height: 35px;
    }
`;

export const Links = styled.div`
    ${tw`px-4 pt-4 mt-auto flex flex-wrap justify-center items-center`}
`;

export const Link = styled.a`
    ${tw`m-1 px-2 py-1 border border-white rounded text-white text-xs`}

    &:hover {
        ${tw`bg-white text-secondary`}
    }
`;
