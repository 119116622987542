import React from 'react';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { GatsbyImage } from 'gatsby-plugin-image';

import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from './styles';

export interface ProjectsFrontmatter {
    title: string;
    subtitle: string;
    projects: [
        {
            title: string;
            description: string;
            technologies: string[];
            links: [
                {
                    href: string;
                    label: string;
                }
            ];
        }
    ];
}

interface ProjectsProps {
    data: {
        frontmatter: ProjectsFrontmatter;
    };
}

const Projects: React.FC<ProjectsProps> = ({ data }) => {
    const { frontmatter } = data;

    const {
        allFile: { edges },
    } = useStaticQuery<{ allFile: { edges: [any] } }>(graphql`
        {
            allFile(filter: { sourceInstanceName: { eq: "images-logos" } }) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);

    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { projects } = frontmatter;

    return (
        <Container>
            <TitleSection center title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
            <Styled.Projects>
                {projects.map((item, index) => {
                    const { title, description, technologies, links } = item;

                    return (
                        <Styled.Project key={index}>
                            <Styled.Card>
                                <Styled.Title>{title}</Styled.Title>
                                <Styled.Description>{description}</Styled.Description>
                                <Styled.Technologies>
                                    {technologies.map((tech) => {
                                        const edge = edges.find(({ node }) => node.name === tech);
                                        const image = edge.node.childImageSharp.gatsbyImageData;

                                        return (
                                            <Styled.Technology key={tech}>
                                                <GatsbyImage
                                                    alt="technology"
                                                    objectFit="contain"
                                                    image={image}
                                                />
                                            </Styled.Technology>
                                        );
                                    })}
                                </Styled.Technologies>
                                <Styled.Links>
                                    {links.map(({ href, label }) => (
                                        <Styled.Link
                                            key={href}
                                            href={href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {label}
                                        </Styled.Link>
                                    ))}
                                </Styled.Links>
                            </Styled.Card>
                        </Styled.Project>
                    );
                })}
            </Styled.Projects>
        </Container>
    );
};

export default Projects;
